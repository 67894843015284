import { format } from "date-fns";
import compareAsc from "date-fns/compareAsc";

import getDay from "date-fns/getDay";
import startOfMonth from "date-fns/startOfMonth";
import getDaysInMonth from "date-fns/getDaysInMonth";

import eachDayOfInterval from "date-fns/eachDayOfInterval";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";

import add from "date-fns/addDays";
import sub from "date-fns/subDays";
import addMonth from "date-fns/addMonths";
import subMonth from "date-fns/subMonths";

import parse from "date-fns/parse";

import getOverlappingDaysInIntervals from "date-fns/getOverlappingDaysInIntervals";

import { ko } from "date-fns/locale";

const FORMAT_DATE = "yyyy-MM-dd";

// 일요일(0), 월요일(1), 화요일(2), 수요일(3), 목요일(4), 금요일(5), 토요일(6)
export const dayByNumber = ["일", "월", "화", "수", "목", "금", "토"];

// 오늘 날짜 가져오기
export function getTodayDate(today = new Date()) {
    const todayDate = format(new Date(today), FORMAT_DATE, {
        locale: ko,
    });

    const dayOfWeekNum = new Date(todayDate).getDay();

    const dayOfWeek = dayByNumber[dayOfWeekNum];

    return [todayDate, dayOfWeekNum];
}

// 내일 날짜 가져오기
export function getNextDay(d, n = 1) {
    const nextDate = format(new Date(add(new Date(d), n)), FORMAT_DATE, {
        locale: ko,
    });
    const dayOfWeekNum = new Date(nextDate).getDay();

    const dayOfWeek = dayByNumber[dayOfWeekNum];
    return [nextDate, dayOfWeekNum];
}

// 어제 날짜 가져오기
export function getPrevDay(d, n = 1) {
    const prevDate = format(new Date(sub(new Date(d), n)), FORMAT_DATE, {
        locale: ko,
    });
    const dayOfWeekNum = new Date(prevDate).getDay();

    const dayOfWeek = dayByNumber[dayOfWeekNum];
    return [prevDate, dayOfWeekNum];
}

// 2주후 날짜
export function getTwoWeeks(d, n = 14) {
    const twoWeekDate = format(new Date(add(new Date(d), n)), FORMAT_DATE, {
        locale: ko,
    });
    const dayOfWeekNum = new Date(twoWeekDate).getDay();

    const dayOfWeek = dayByNumber[dayOfWeekNum];
    return [twoWeekDate, dayOfWeekNum];
}

// 다음달 가져오기
export function getNextMonth(d, n = 1) {
    const nextDate = format(new Date(addMonth(new Date(d), n)), FORMAT_DATE, {
        locale: ko,
    });
    const dayOfWeekNum = getDay(new Date(nextDate));

    const dayOfWeek = dayByNumber[dayOfWeekNum];
    return [nextDate, dayOfWeekNum];
}

// 어제 날짜 가져오기
export function getPrevMonth(d, n = 1) {
    const prevDate = format(new Date(subMonth(new Date(d), n)), FORMAT_DATE, {
        locale: ko,
    });
    const dayOfWeekNum = getDay(new Date(prevDate));

    const dayOfWeek = dayByNumber[dayOfWeekNum];
    return [prevDate, dayOfWeekNum];
}

// 해당 년-월의 첫번째 요일
export function getFirstDayByMonth(d) {
    const startDay = startOfMonth(new Date(d));

    return getDay(startDay);
}

// 날짜를 기준으로 n개만큼 가져오기
export function getRangeDate(d, range = 1) {
    const temp = [];

    for (let i = 0; i < range; ++i) {
        const formated = format(new Date(add(new Date(d), i)), FORMAT_DATE, {
            locale: ko,
        });
        const dayOfWeekNum = getDay(new Date(formated));

        const dayOfWeek = dayByNumber[dayOfWeekNum];
        temp.push([formated, dayOfWeekNum]);
    }
    return temp;
}

// 해당 달에 요일갯수
export function getCountDaysByMonth(focusDate) {
    return getDaysInMonth(new Date(focusDate));
}

// 시작 ~ 마지막 날짜를 전달 받으면 그 사이의 날짜를 반환한다.
export function getRangeDates(startDate, endDate) {
    return eachDayOfInterval({
        start: new Date(startDate),
        end: new Date(endDate),
    }).map((date) => format(new Date(date), FORMAT_DATE));
}

export function isAfterAndSame(startDate, endDate) {
    if ((!startDate && endDate) || (startDate && !endDate)) {
        return true;
    }

    const _isAfter = isAfter(new Date(endDate), new Date(startDate));

    const _isBefore = isBefore(new Date(endDate), new Date(startDate));

    if (!_isAfter && !_isBefore) {
        return true;
    } else if (_isAfter) {
        return true;
    } else {
        return false;
    }
}

// 포맷 변환
export function formatByString(d, f) {
    if (!d) return "";
    return format(new Date(d), f);
}
// 포맷 변환
export function format_YYYYMMDD(y, m, d) {
    return format(new Date(y, m - 1, d), FORMAT_DATE);
}

// 포맷 변환
export function formatByString_YYYYMMDD(d) {
    return format(new Date(d), FORMAT_DATE);
}

// Parse 변환 string -> date
export function parseStringToDate(d, f = "yyyy-MM-dd") {
    return parse(d, f, new Date());
}

export function isMonthSame(d1, d2) {
    return (
        d1.split("-")[0] === d2.split("-")[0] &&
        d1.split("-")[1] === d2.split("-")[1]
    );
}

// 크기비교 source < target true
export function rightBig(source, target) {
    return compareAsc(new Date(source), new Date(target)) === -1;
}

// 크기비교 source > target false
export function leftBig(source, target) {
    return compareAsc(new Date(source), new Date(target)) !== -1;
}

// 충돌비교
export function isRangeOverlapping(t1, t2) {
    return getOverlappingDaysInIntervals(
        {
            start: new Date(t1.stay_after),
            end: new Date(t1.stay_before),
        },
        {
            start: new Date(t2.stay_after),
            end: new Date(t2.stay_before),
        }
    );
}
