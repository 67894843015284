import api from "./api";

export default class BoardApi {
    static async List(body, data) {
        return await api.post("board/list", body, data);
    }

    static async Detail(data) {
        return await api.get("board/detail", data);
    }

    static async Create(data) {
        return await api.post("board/create", data);
    }

    static async Update(data) {
        return await api.put("board/update", data);
    }

    static async StatusUpdate(data, params) {
        return await api.put("board/status/list", data, params);
    }

    static async StatusMainUpdate(data, params) {
        return await api.put("board/status/main/list", data, params);
    }
}
