export const selectData = [
    {
        value: "0",
        text: "신규",
    },
    {
        value: "1",
        text: "읽음",
    },
    {
        value: "2",
        text: "보류",
    },
    {
        value: "99",
        text: "삭제",
    },
];
