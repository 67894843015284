import { useEffect, useState } from "react";
import { Breadcrumb, Button, Radio } from "antd";
import { Link } from "react-router-dom";

import { BoardColumn } from "../../../components/columns/BoardColumn";

import BoardApi from "../../../api/board";
import TaggingApi from "../../../api/tagging";

import Table from "../../../components/atoms/Table";
import Select from "../../../components/atoms/Select";

import {
  selectStatusData,
  selectCategoryData,
  selectTypeFilterData,
} from "../../../components/selectDatas/columnNews";

function List() {
  const [loading, setLoading] = useState(false);
  const [boardData, setBoardData] = useState([]);
  const [boardColumn, setBoardColumn] = useState([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectValue, setSelectValue] = useState(0);
  const [selectRadioValue, setSelectRadioValue] = useState(0);
  const [categoryRadioValue, setCategoryRadioValue] = useState(0);
  const [typeRadioValue, setTypeRadioValue] = useState(99);

  const [tagData, setTagData] = useState([]);
  const [checkedList, setCheckedList] = useState(undefined);

  useEffect(() => {
    (async () => tagLoad())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryRadioValue]);

  useEffect(() => {
    (async () => load())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectRadioValue, categoryRadioValue, typeRadioValue, checkedList]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const onChangeCheckBox = (list) => {
    setCheckedList(list);
  };

  const tagLoad = async () => {
    try {
      setLoading(true);

      const query = {
        b_category: categoryRadioValue,
      };

      const response = await TaggingApi.List(query);
      const processedData = response.data.map((item) => {
        return {
          label: item.Name,
          value: item.TId,
        };
      });
      setTagData(processedData);
      setCheckedList(undefined);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const load = async () => {
    try {
      setLoading(true);

      const query = {
        Status: Number(selectRadioValue) === 2 ? 0 : selectRadioValue,
        Board_Category: Number(categoryRadioValue),
        Board_Type: typeRadioValue === 99 ? null : Number(typeRadioValue),
        IsMain: Number(selectRadioValue) === 2 ? 1 : null,
        TagTId: checkedList,
      };
      const response = await BoardApi.List(undefined, query);
      const processedData = response.data.map((item) => {
        return {
          key: item.BId,
          ...item,
        };
      });

      setBoardData(processedData);
      setBoardColumn([
        ...BoardColumn,
        {
          title: "태그",
          width: 50,
          dataIndex: "Tag",
          key: "TagTId",
          render: (value) => value?.Name ?? "",
        },
      ]);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const statusChangeSubmit = async () => {
    try {
      setLoading(true);

      const query = {
        status: selectValue === 2 ? 1 : selectValue,
      };

      if (selectValue === 2) {
        await BoardApi.StatusMainUpdate(selectedRowKeys, query);
      } else {
        await BoardApi.StatusUpdate(selectedRowKeys, query);
      }
      load();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Breadcrumb
            style={{
              textAlign: "left",
              margin: "16px",
              fontWeight: "bold",
              fontSize: 18,
            }}
          >
            <Breadcrumb.Item>
              {categoryRadioValue === 0
                ? "컬럼"
                : categoryRadioValue === 1
                ? "뉴스"
                : "활용사례"}
            </Breadcrumb.Item>
            <Breadcrumb.Item>목록</Breadcrumb.Item>
          </Breadcrumb>
          <Button
            type="primary"
            size="middle"
            style={{
              marginRight: 10,
            }}
          >
            <Link to={"/Home/Board/edit"}>등록하기</Link>
          </Button>
        </div>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: "100vh" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: 16,
              }}
            >
              {categoryRadioValue === 0
                ? "컬럼"
                : categoryRadioValue === 1
                ? "뉴스"
                : "활용사례"}{" "}
              갯수 : {boardData.length}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <span style={{ marginRight: 12 }}>
                선택된{" "}
                {categoryRadioValue === 0
                  ? "컬럼"
                  : categoryRadioValue === 1
                  ? "뉴스"
                  : "활용사례"}{" "}
                갯수 : 갯수 :
                {selectedRowKeys.length > 0
                  ? ` ${selectedRowKeys.length}`
                  : " 0"}
              </span>

              <Select
                style={{
                  width: 140,
                  textAlign: "left",
                  marginRight: 12,
                }}
                data={selectStatusData}
                value={selectValue}
                onChange={(value) => {
                  setSelectValue(value);
                }}
              />

              <Button
                type="primary"
                onClick={() => {
                  statusChangeSubmit();
                }}
              >
                변경하기
              </Button>
            </div>
          </div>

          <div
            style={{
              marginBottom: 10,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Radio.Group
              buttonStyle="solid"
              value={categoryRadioValue}
              onChange={async (e) => {
                setCategoryRadioValue(e.target.value);
              }}
            >
              {selectCategoryData.map((d) => (
                <Radio.Button style={{ width: 100 }} value={d.value}>
                  {d.text}
                </Radio.Button>
              ))}
            </Radio.Group>

            <div
              style={{
                marginLeft: 40,
              }}
            >
              <Radio.Group
                value={typeRadioValue}
                onChange={async (e) => {
                  setTypeRadioValue(e.target.value);
                }}
              >
                {selectTypeFilterData.map((d) => (
                  <Radio style={{ width: 70 }} value={d.value}>
                    {d.text}
                  </Radio>
                ))}
              </Radio.Group>
            </div>
          </div>

          <div
            style={{
              marginBottom: 20,
              display: "flex",
            }}
          >
            <Radio.Group
              buttonStyle="solid"
              value={selectRadioValue}
              onChange={async (e) => {
                setSelectRadioValue(e.target.value);
              }}
            >
              {selectStatusData.map((d) => (
                <Radio.Button style={{ width: 100 }} value={d.value}>
                  {d.text}
                </Radio.Button>
              ))}
            </Radio.Group>
          </div>

          <div
            style={{
              display: "flex",
              marginBottom: 20,
            }}
          >
            <Radio.Group
              value={checkedList}
              onChange={async (e) => {
                onChangeCheckBox(e.target.value);
              }}
            >
              <Radio style={{ width: 70 }} value={undefined}>
                전체
              </Radio>
              {tagData.map((d) => (
                <Radio style={{ width: 70 }} value={d.value}>
                  {d.label}
                </Radio>
              ))}
            </Radio.Group>
          </div>

          <Table
            loading={loading}
            rowSelection={rowSelection}
            columns={boardColumn}
            dataSource={boardData}
          />
        </div>
      </div>
    </>
  );
}

export default List;
