import { formatByString } from "../../utils/Date";
import { Link } from "react-router-dom";

export const JobpostingColumn = [
    {
        title: "No.",
        width: 50,
        dataIndex: "JId",
        key: "JId",
    },
    {
        title: "직업",
        width: 100,
        dataIndex: "Occupation",
        key: "Occupation",
        render: (value, record, index) => {
            return (
                <Link to={`/Home/Jobposting/edit/${record.JId}`}>{value}</Link>
            );
        },
    },
    {
        title: "직업상세",
        width: 150,
        dataIndex: "Occupation_Detail",
        key: "Occupation_Detail",
    },
    {
        title: "직종",
        width: 100,
        dataIndex: "Worker_Type",
        key: "Worker_Type",
    },
    {
        title: "작성 날짜",
        width: 100,
        dataIndex: "Created_Date",
        key: "Created_Date",
        render: (value, record, index) => {
            return <div>{formatByString(value, "yyyy-MM-dd HH:mm")}</div>;
        },
    },
];
