import { useEffect, useState } from "react";
import { Layout, Menu, Breadcrumb, Button, Radio } from "antd";

import { SubscribeColumn } from "../../../components/columns/SubscribeColumn";

import SubscribeApi from "../../../api/subscribe";
import Table from "../../../components/atoms/Table";
import Select from "../../../components/atoms/Select";
import { selectData } from "../../../components/selectDatas/requestStatus";

function List() {
    const [loading, setLoading] = useState(false);
    const [subscribeData, setSubscribeData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectValue, setSelectValue] = useState("0");
    const [selectRadioValue, setSelectRadioValue] = useState("0");

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        },
    };

    const hasSelected = selectedRowKeys.length > 0;

    const load = async () => {
        try {
            setLoading(true);

            const status = { status: selectRadioValue };
            const response = await SubscribeApi.List(status);
            const processedData = response.data.map((item) => {
                return {
                    key: item.SId,
                    ...item,
                };
            });

            setSubscribeData(processedData);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const statusChangeSubmit = async () => {
        try {
            setLoading(true);

            const status = { status: selectValue };
            const response = await SubscribeApi.StatusUpdate(
                selectedRowKeys,
                status
            );

            load();
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(async () => {
        load();
    }, [selectRadioValue]);

    return (
        <>
            <Breadcrumb
                style={{
                    textAlign: "left",
                    margin: "16px",
                    fontWeight: "bold",
                    fontSize: 18,
                }}
            >
                <Breadcrumb.Item>메일 구독</Breadcrumb.Item>
                <Breadcrumb.Item>목록</Breadcrumb.Item>
            </Breadcrumb>
            <div
                className="site-layout-background"
                style={{ padding: 24, minHeight: "100vh" }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: 10,
                    }}
                >
                    <div
                        style={{
                            fontWeight: "bold",
                            fontSize: 16,
                        }}
                    >
                        메일 구독 갯수 : {subscribeData.length}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        <span style={{ marginRight: 12 }}>
                            선택된 메일 구독 갯수 :
                            {hasSelected ? ` ${selectedRowKeys.length}` : " 0"}
                        </span>

                        <Select
                            style={{
                                width: 140,
                                textAlign: "left",
                                marginRight: 12,
                            }}
                            data={selectData}
                            defaultValue={"0"}
                            value={selectValue}
                            onChange={(value) => {
                                setSelectValue(value);
                            }}
                        />

                        <Button
                            type="primary"
                            onClick={() => {
                                statusChangeSubmit();
                            }}
                        >
                            변경하기
                        </Button>
                    </div>
                </div>

                <div
                    style={{
                        marginBottom: 20,
                        display: "flex",
                    }}
                >
                    <Radio.Group
                        defaultValue={"0"}
                        buttonStyle="solid"
                        value={selectRadioValue}
                        onChange={async (e) => {
                            setSelectRadioValue(e.target.value);
                        }}
                    >
                        {selectData.map((d) => (
                            <Radio.Button style={{ width: 80 }} value={d.value}>
                                {d.text}
                            </Radio.Button>
                        ))}
                    </Radio.Group>
                </div>

                <Table
                    loading={loading}
                    rowSelection={rowSelection}
                    columns={SubscribeColumn}
                    dataSource={subscribeData}
                />
            </div>
        </>
    );
}

export default List;
