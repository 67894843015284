import { useEffect, useState } from "react";
import { Layout, Menu, Breadcrumb, Button, Radio } from "antd";
import { Link } from "react-router-dom";

import JobpostingApi from "../../../api/jobposting";

import { JobpostingColumn } from "../../../components/columns/JobpostingColumn";

import Table from "../../../components/atoms/Table";
import Select from "../../../components/atoms/Select";

import { selectStatusData } from "../../../components/selectDatas/columnNews";

function List() {
    const [loading, setLoading] = useState(false);
    const [jobpostingData, setJobpostingData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectValue, setSelectValue] = useState(0);
    const [selectRadioValue, setSelectRadioValue] = useState(0);

    useEffect(async () => {
        load();
    }, [selectRadioValue]);

    const load = async () => {
        try {
            setLoading(true);

            const query = {
                status: selectRadioValue,
            };

            const response = await JobpostingApi.List(query);
            const processedData = response.data.map((item) => {
                return {
                    key: item.JId,
                    ...item,
                };
            });

            setJobpostingData(processedData);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        },
    };

    const statusChangeSubmit = async () => {
        try {
            setLoading(true);

            const query = {
                status: selectValue,
            };

            await JobpostingApi.StatusUpdate(selectedRowKeys, query);
            load();
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Breadcrumb
                    style={{
                        textAlign: "left",
                        margin: "16px",
                        fontWeight: "bold",
                        fontSize: 18,
                    }}
                >
                    <Breadcrumb.Item>채용 공고</Breadcrumb.Item>
                    <Breadcrumb.Item>목록</Breadcrumb.Item>
                </Breadcrumb>
                <Button
                    type="primary"
                    size="middle"
                    style={{
                        marginRight: 10,
                    }}
                >
                    <Link to={"/Home/Jobposting/edit"}>등록하기</Link>
                </Button>
            </div>
            <div
                className="site-layout-background"
                style={{ padding: 24, minHeight: "100vh" }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: 10,
                    }}
                >
                    <div
                        style={{
                            fontWeight: "bold",
                            fontSize: 16,
                        }}
                    >
                        채용공고 갯수 : {jobpostingData.length}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        <span style={{ marginRight: 12 }}>
                            선택된 채용공고 갯수 :
                            {selectedRowKeys.length > 0
                                ? ` ${selectedRowKeys.length}`
                                : " 0"}
                        </span>

                        <Select
                            style={{
                                width: 140,
                                textAlign: "left",
                                marginRight: 12,
                            }}
                            data={selectStatusData}
                            value={selectValue}
                            onChange={(value) => {
                                setSelectValue(value);
                            }}
                        />

                        <Button
                            type="primary"
                            onClick={() => {
                                statusChangeSubmit();
                            }}
                        >
                            변경하기
                        </Button>
                    </div>
                </div>

                <div
                    style={{
                        marginBottom: 20,
                        display: "flex",
                    }}
                >
                    <Radio.Group
                        buttonStyle="solid"
                        value={selectRadioValue}
                        onChange={async (e) => {
                            setSelectRadioValue(e.target.value);
                        }}
                    >
                        {selectStatusData.map((d) => (
                            <Radio.Button style={{ width: 80 }} value={d.value}>
                                {d.text}
                            </Radio.Button>
                        ))}
                    </Radio.Group>
                </div>

                <Table
                    loading={loading}
                    rowSelection={rowSelection}
                    columns={JobpostingColumn}
                    dataSource={jobpostingData}
                />
            </div>
        </>
    );
}

export default List;
