import { formatByString } from "../../utils/Date";
import { Button, Popconfirm, message } from "antd";

export const TaggingColumn = [
    {
        title: "No.",
        width: 50,
        dataIndex: "TId",
        key: "TId",
    },
    {
        title: "이름",
        dataIndex: "Name",
        key: "Name",
    },
    {
        title: "색상",
        width: 100,
        dataIndex: "Color",
        key: "Color",
    },
    {
        title: "생성 날짜",
        width: 150,
        dataIndex: "Created_Date",
        key: "Created_Date",
        render: (value, record, index) => {
            return <div>{formatByString(value, "yyyy-MM-dd HH:mm")}</div>;
        },
    },
];
