import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb } from "antd";
import {
    DesktopOutlined,
    FileOutlined,
    FormOutlined,
    MailOutlined,
    TagsOutlined,
} from "@ant-design/icons";

import "../css/Home.css";

import ContentRouter from "../routes/ContentRouter";

const { Content, Sider } = Layout;

function Home(props) {
    const [collapsed, setCollapsed] = useState(false);
    const [selectKey, setSelectKey] = useState("1");

    const onCollapse = (collapsed) => {
        setCollapsed(collapsed);
    };

    useEffect(() => {
        if (props.location.pathname.includes("/Board")) {
            setSelectKey("1");
        } else if (props.location.pathname.includes("/Jobposting")) {
            setSelectKey("2");
        } else if (props.location.pathname.includes("/ContactUs")) {
            setSelectKey("3");
        } else if (props.location.pathname.includes("/Subscribe")) {
            setSelectKey("4");
        } else if (props.location.pathname.includes("/Tagging")) {
            setSelectKey("5");
        }
    }, [props.location.pathname]);

    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
                {collapsed ? (
                    <div className="logo02" />
                ) : (
                    <div className="logo" />
                )}

                <Menu
                    theme="dark"
                    mode="inline"
                    selectedKeys={selectKey}
                    onSelect={(value) => {
                        setSelectKey(value.key);
                    }}
                >
                    <Menu.Item key="1" icon={<FormOutlined />}>
                        <Link to={"/Home/Board"} />
                        컬럼, 뉴스, 활용사례
                    </Menu.Item>
                    <Menu.Item key="2" icon={<DesktopOutlined />}>
                        <Link to={"/Home/Jobposting"} />
                        채용 공고
                    </Menu.Item>
                    <Menu.Item key="3" icon={<FileOutlined />}>
                        <Link to={"/Home/ContactUs"} />
                        문의 내역
                    </Menu.Item>
                    <Menu.Item key="4" icon={<MailOutlined />}>
                        <Link to={"/Home/Subscribe"} />
                        메일 구독
                    </Menu.Item>
                    <Menu.Item key="5" icon={<TagsOutlined />}>
                        <Link to={"/Home/Tagging"} />
                        태그 관리
                    </Menu.Item>
                </Menu>
            </Sider>
            <Layout className="site-layout">
                <Content style={{ margin: "0 16px" }}>
                    <ContentRouter props={props} />
                </Content>
            </Layout>
        </Layout>
    );
}

export default Home;
