import api from "./api";

export default class JobpostingApi {
    static async List(data) {
        return await api.get("jobposting/list", data);
    }

    static async Detail(data) {
        return await api.get("jobposting/detail", data);
    }

    static async Create(data) {
        return await api.post("jobposting/create", data);
    }

    static async Update(data) {
        return await api.put("jobposting/update", data);
    }

    static async StatusUpdate(data, params) {
        return await api.put("jobposting/status/list", data, params);
    }
}
