import { formatByString } from "../../utils/Date";

export const SubscribeColumn = [
    {
        title: "No.",
        width: 50,
        dataIndex: "SId",
        key: "SId",
    },
    {
        title: "이메일",
        dataIndex: "Email",
        key: "Email",
    },
    {
        title: "구독 요청 날짜",
        width: 150,
        dataIndex: "Created_Date",
        key: "Created_Date",
        render: (value, record, index) => {
            return <div>{formatByString(value, "yyyy-MM-dd HH:mm")}</div>;
        },
    },
];
