import { Route, Switch, Redirect } from "react-router-dom";

import Home from "../pages/Home";

const Router = () => {
    return (
        <Switch>
            <Route path="/Home" component={Home} />

            {/* Not Found */}
            <Route component={() => <Redirect to="/Home" />} />
        </Switch>
    );
};

export default Router;
