import { formatByString } from "../../utils/Date";
import { Link } from "react-router-dom";

export const BoardColumn = [
    {
        title: "No.",
        width: 50,
        dataIndex: "BId",
        key: "BId",
    },
    {
        title: "썸네일",
        width: 150,
        dataIndex: "Thumbnail",
        key: "Thumbnail",
        render: (value, record, index) => {
            return record.Thumbnail ? (
                <img
                    src={record.Thumbnail}
                    alt="avatar"
                    style={{
                        width: "80%",
                        height: 150,
                    }}
                ></img>
            ) : (
                <div
                    style={{
                        fontSize: 13,
                    }}
                >
                    썸네일 이미지가 없습니다.
                </div>
            );
        },
    },
    {
        title: "제목",
        width: 200,
        dataIndex: "Title",
        key: "Title",
        render: (value, record, index) => {
            return <Link to={`/Home/Board/edit/${record.BId}`}>{value}</Link>;
        },
    },
    {
        title: "Type",
        width: 50,
        dataIndex: "Board_Type",
        key: "Board_Type",
        render: (value, record, index) => {
            switch (value) {
                case 0: {
                    return <div>Image</div>;
                }
                case 1: {
                    return <div>Movie</div>;
                }
                case 2: {
                    return <div>Text</div>;
                }
            }
        },
    },
    {
        title: "작성 날짜",
        width: 80,
        dataIndex: "Created_Date",
        key: "Created_Date",
        render: (value, record, index) => {
            return <div>{formatByString(value, "yyyy-MM-dd HH:mm")}</div>;
        },
    },
];
