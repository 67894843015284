import { Table as AntdTable } from "antd";

import "./index.css";

function Table(props) {
    return (
        <>
            <div className="custom-table">
                <AntdTable
                    {...props}
                    scroll={{ x: 1500 }}
                    pagination={{
                        position: ["bottomCenter"],
                        defaultPageSize: 30,
                    }}
                    size="middle"
                />
            </div>
        </>
    );
}

export default Table;
