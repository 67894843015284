import { useEffect, useState } from "react";
import { Row, Col, Breadcrumb, Button, Input, Upload, message } from "antd";
import { useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { CheckCircleTwoTone, PlusOutlined } from "@ant-design/icons";

import "../../../css/ColumnNews/Edit.css";

import UploadApi from "../../../api/upload";
import BoardApi from "../../../api/board";
import TaggingApi from "../../../api/tagging";

import Select from "../../../components/atoms/Select";
import {
    selectStatusData,
    selectCategoryData,
    selectTypeData,
} from "../../../components/selectDatas/columnNews";

function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
        message.error("JPG/PNG file만 업로드 가능합니다.");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error("업로드 가능한 이미지 최대 용량은 2MB입니다.");
    }

    return isJpgOrPng && isLt2M;
}

async function customRequest({ file, onSuccess, onError }) {
    try {
        const response = await UploadApi.upload(file);

        if (response.data?.location) {
            onSuccess(response.data.location);
        } else {
            onError("이미지 업로드 실패!");
        }
    } catch (ex) {
        console.log(ex);
        onError(ex);
    }
}

function Edit(props) {
    let { boardId } = useParams();

    const [tagData, setTagData] = useState([]);
    const [editInitialValue, setEditInitialValue] = useState("");
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState();
    const [imageList, setImageList] = useState([]);
    const [boardData, setBoardData] = useState({
        Board_Category: 0,
        Board_Type: 0,
        Thumbnail: "",
        Title: "",
        Contents: "",
        TagTId: 0,
        Status: 0,
        keyword: '',
        description: ''
    });

    useEffect(async () => {
        tagLoad();
    }, [boardData.Board_Category]);

    useEffect(() => {
        if (boardId) {
            boardLoad();
        }
    }, []);

    useEffect(() => {
        if (image) {
            setImageList([
                ...imageList,
                {
                    url: image,
                    checked: false,
                },
            ]);
        }
    }, [image]);

    const tagLoad = async () => {
        try {
            setLoading(true);

            const query = {
                b_category: boardData.Board_Category,
            };

            const response = await TaggingApi.List(query);
            if (response.data?.length > 0) {
                const processedData = response.data.map((item) => {
                    return {
                        text: item.Name,
                        value: item.TId,
                    };
                });
                setTagData(processedData);

                setBoardData({
                    ...boardData,
                    TagTId: response.data[0].TId,
                });
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const boardLoad = async () => {
        try {
            const query = {
                bId: boardId,
            };
            const { data } = await BoardApi.Detail(query);

            setBoardData(data);
            setEditInitialValue(data.Contents);
            setImageList([
                {
                    url: data.Thumbnail,
                    checked: true,
                },
            ]);
        } catch (err) {
            console.log(err);
        }
    };

    const handleEditorChange = (e) => {
        setBoardData({
            ...boardData,
            Contents: e.target.getContent(),
        });
    };

    const imageChecked = (url) => {
        if (imageList.length > 0) {
            let findIndex = imageList.findIndex((item) => item.url === url);
            let tempImageList = imageList.map((item) => {
                return {
                    url: item.url,
                    checked: false,
                };
            });

            tempImageList[findIndex] = {
                url: url,
                checked: true,
            };

            setImageList(tempImageList);
            setBoardData({
                ...boardData,
                Thumbnail: url,
            });
        }
    };

    const imageUploadHandler = async (blobInfo, success, failure) => {
        {
            try {
                const response = await UploadApi.upload(blobInfo.blob());

                if (response.data?.location) {
                    setImage(response.data.location);
                    success(response.data.location);
                } else {
                    failure("이미지 업로드 실패!");
                }
            } catch (err) {
                console.log(err);
            }
        }
    };

    const handleChange = (info) => {
        if (info.file.status === "uploading") {
            setLoading(true);
            return;
        }
        if (info.file.status === "done") {
            setLoading(false);
            setImage(info.file.response);
        }
    };

    const saveSubmit = async () => {
        try {
            setLoading(true);

            if (boardData.Title === "") {
                return message.warning("제목을 입력해주세요.");
            }

            if (boardData.Board_Type !== "2" && boardData.Thumbnail === "") {
                return message.warning("썸네일을 선택해주세요.");
            }

            if (boardId) {
                await BoardApi.Update(boardData);
                props.history.goBack();
                message.success("정상적으로 수정되었습니다.");
            } else {
                await BoardApi.Create(boardData);
                props.history.goBack();
                message.success("정상적으로 등록되었습니다.");
            }
        } catch (err) {
            console.log(err);
            message.error("통신에 실패하였습니다.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Breadcrumb
                    style={{
                        textAlign: "left",
                        margin: "16px",
                        fontWeight: "bold",
                        fontSize: 18,
                    }}
                >
                    <Breadcrumb.Item>컬럼, 뉴스, 활용사례</Breadcrumb.Item>
                    <Breadcrumb.Item>목록</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {boardId ? "수정" : "등록"}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Button
                    type="primary"
                    size="middle"
                    style={{
                        marginRight: 10,
                    }}
                    onClick={saveSubmit}
                    loading={loading}
                >
                    {boardId ? "수정" : "등록"}
                </Button>
            </div>
            <div
                className="site-layout-background"
                style={{ padding: 24, minHeight: "100vh" }}
            >
                <div>
                    <div className={"input-frame"}>
                        <div className={"title"}>제목 :</div>
                        <Input
                            placeholder="제목을 입력해주세요."
                            value={boardData.Title}
                            onChange={(e) => {
                                setBoardData({
                                    ...boardData,
                                    Title: e.target.value,
                                });
                            }}
                        />
                    </div>

                    <div className={"input-frame"}>
                        <div className={"title"}>상태 :</div>
                        <Select
                            className={"content"}
                            style={{
                                width: "100%",
                                textAlign: "left",
                            }}
                            data={selectStatusData}
                            value={boardData.Status}
                            onChange={(value) => {
                                setBoardData({
                                    ...boardData,
                                    Status: value,
                                });
                            }}
                        />
                    </div>

                    <div className={"input-frame"}>
                        <div className={"title"}>카테고리 :</div>
                        <Select
                            style={{
                                width: "100%",
                                textAlign: "left",
                            }}
                            data={selectCategoryData}
                            value={boardData.Board_Category}
                            onChange={(value) => {
                                setBoardData({
                                    ...boardData,
                                    Board_Category: value,
                                });
                            }}
                        />
                    </div>

                    <div className={"input-frame"}>
                        <div className={"title"}>타입 :</div>
                        <Select
                            style={{
                                width: "100%",
                                textAlign: "left",
                            }}
                            data={selectTypeData}
                            value={boardData.Board_Type}
                            onChange={(value) => {
                                setBoardData({
                                    ...boardData,
                                    Board_Type: value,
                                });
                            }}
                        />
                    </div>

                    <div className={"input-frame"}>
                        <div className={"title"}>태그 :</div>
                        <Select
                            style={{
                                width: "100%",
                                textAlign: "left",
                            }}
                            data={tagData}
                            value={boardData.TagTId}
                            onChange={(value) => {
                                setBoardData({
                                    ...boardData,
                                    TagTId: value,
                                });
                            }}
                        />
                    </div>

                    <div className={"input-frame"}>
                        <div className={"title"}>설명 :</div>
                        <Input
                            placeholder="설명을 입력해주세요."
                            value={boardData.description}
                            onChange={(e) => {
                                setBoardData({
                                    ...boardData,
                                    description: e.target.value,
                                });
                            }}
                        />
                    </div>
                    <div className={"input-frame"}>
                        <div className={"title"}>키워드 :</div>
                        <Input
                            placeholder="키워드를 입력해주세요."
                            value={boardData.keyword}
                            onChange={(e) => {
                                console.log(e.target.value)
                                setBoardData({
                                    ...boardData,
                                    keyword: e.target.value,
                                });
                            }}
                        />
                    </div>


                    <div className={"input-frame"}>
                        <div className={"title"}>썸네일 이미지 :</div>
                        <div
                            style={{
                                width: "100%",
                                textAlign: "left",
                            }}
                        >
                            <Row gutter={[16, 16]}>
                                {imageList.map((item, index) => {
                                    return (
                                        <Col span={4} key={index}>
                                            <button
                                                style={{
                                                    border: 0,
                                                    background: "#fff",
                                                }}
                                                onClick={() => {
                                                    imageChecked(item.url);
                                                }}
                                            >
                                                <img
                                                    src={item.url}
                                                    alt="avatar"
                                                    style={{
                                                        width: "100%",
                                                        minWidth: 230,
                                                        height: 150,
                                                    }}
                                                />
                                            </button>
                                            {item.checked ? (
                                                <div
                                                    style={{
                                                        background: "#d6d6d6",
                                                        zIndex: 999,
                                                        position: "absolute",
                                                        top: 0,
                                                        width: "98%",
                                                        height: 150,
                                                        opacity: 0.7,
                                                    }}
                                                >
                                                    <CheckCircleTwoTone
                                                        twoToneColor="#52c41a"
                                                        style={{
                                                            fontSize: 20,
                                                            padding: 6,
                                                        }}
                                                    />
                                                </div>
                                            ) : null}
                                        </Col>
                                    );
                                })}

                                <Col span={4}>
                                    <Upload
                                        name="avatar"
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        accept="image/*"
                                        showUploadList={false}
                                        customRequest={customRequest}
                                        beforeUpload={beforeUpload}
                                        onChange={handleChange}
                                    >
                                        <div>
                                            <PlusOutlined />
                                            <div style={{ marginTop: 8 }}>
                                                Upload
                                            </div>
                                        </div>
                                    </Upload>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>

                <Editor
                    apiKey="fpkgh57ghp4vy8jj8o7s3fykta3aofbjjmkh7fiti3x3q5k0"
                    initialValue={editInitialValue}
                    init={{
                        language: "ko_KR", //한글판으로 변경
                        selector: "textarea#full-featured-non-premium",
                        plugins:
                            "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                        imagetools_cors_hosts: ["picsum.photos"],
                        menubar:
                            "file edit view insert format tools table help",
                        toolbar:
                            "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
                        toolbar_sticky: true,
                        autosave_ask_before_unload: true,
                        autosave_interval: "30s",
                        autosave_prefix: "{path}{query}-{id}-",
                        autosave_restore_when_empty: false,
                        autosave_retention: "2m",
                        image_advtab: true,
                        link_list: [
                            {
                                title: "My page 1",
                                value: "https://www.tiny.cloud",
                            },
                            {
                                title: "My page 2",
                                value: "http://www.moxiecode.com",
                            },
                        ],
                        image_list: [
                            {
                                title: "My page 1",
                                value: "https://www.tiny.cloud",
                            },
                            {
                                title: "My page 2",
                                value: "http://www.moxiecode.com",
                            },
                        ],
                        image_class_list: [
                            { title: "None", value: "" },
                            { title: "Some class", value: "class-name" },
                        ],
                        importcss_append: true,
                        file_picker_callback: function (callback, value, meta) {
                            /* Provide file and text for the link dialog */
                            if (meta.filetype === "file") {
                                callback(
                                    "https://www.google.com/logos/google.jpg",
                                    { text: "My text" }
                                );
                            }

                            /* Provide image and alt text for the image dialog */
                            if (meta.filetype === "image") {
                                callback(
                                    "https://www.google.com/logos/google.jpg",
                                    { alt: "My alt text" }
                                );
                            }

                            /* Provide alternative source and posted for the media dialog */
                            if (meta.filetype === "media") {
                                callback("movie.mp4", {
                                    source2: "alt.ogg",
                                    poster: "https://www.google.com/logos/google.jpg",
                                });
                            }
                        },
                        images_upload_handler: async function (
                            blobInfo,
                            success,
                            failure
                        ) {
                            imageUploadHandler(blobInfo, success, failure);
                        },
                        templates: [
                            {
                                title: "New Table",
                                description: "creates a new table",
                                content:
                                    '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
                            },
                            {
                                title: "Starting my story",
                                description: "A cure for writers block",
                                content: "Once upon a time...",
                            },
                            {
                                title: "New list with dates",
                                description: "New List with dates",
                                content:
                                    '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
                            },
                        ],
                        template_cdate_format:
                            "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
                        template_mdate_format:
                            "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
                        height: 800,
                        image_caption: true,
                        quickbars_selection_toolbar:
                            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                        noneditable_noneditable_class: "mceNonEditable",
                        toolbar_mode: "sliding",
                        contextmenu: "link image imagetools table",
                        skin: "oxide-dark",
                        content_css: "dark",
                        content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                    onChange={handleEditorChange}
                />
            </div>
        </>
    );
}

export default Edit;
