// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../assets/icons/logo.png";
import ___CSS_LOADER_URL_IMPORT_1___ from "../assets/icons/logo02.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ant-layout-sider-children .logo {\n    height: 32px;\n    margin: 16px;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    background-size: 100px;\n    background-position: center;\n}\n\n.ant-layout-sider-children .logo02 {\n    height: 68px;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n    background-repeat: no-repeat;\n    background-size: 100px;\n    background-position: center;\n}\n\n.site-layout .site-layout-background {\n    background: #fff;\n}\n", "",{"version":3,"sources":["webpack://src/css/Home.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,yDAAiD;IACjD,4BAA4B;IAC5B,sBAAsB;IACtB,2BAA2B;AAC/B;;AAEA;IACI,YAAY;IACZ,yDAAmD;IACnD,4BAA4B;IAC5B,sBAAsB;IACtB,2BAA2B;AAC/B;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".ant-layout-sider-children .logo {\n    height: 32px;\n    margin: 16px;\n    background-image: url(/src/assets/icons/logo.png);\n    background-repeat: no-repeat;\n    background-size: 100px;\n    background-position: center;\n}\n\n.ant-layout-sider-children .logo02 {\n    height: 68px;\n    background-image: url(/src/assets/icons/logo02.png);\n    background-repeat: no-repeat;\n    background-size: 100px;\n    background-position: center;\n}\n\n.site-layout .site-layout-background {\n    background: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
