import api from "./api";

export default class TaggingApi {
    static async List(data) {
        return await api.get("tagging/list", data);
    }

    static async Create(data) {
        return await api.post("tagging/create", data);
    }

    static async Delete(data) {
        return await api.put("tagging/delete/list", data);
    }
}
