import { useEffect, useState } from "react";
import {
    Modal,
    Breadcrumb,
    Button,
    Popconfirm,
    Input,
    message,
    Radio,
} from "antd";

import "../../../css/ColumnNews/Edit.css";

import TaggingApi from "../../../api/tagging";

import Table from "../../../components/atoms/Table";
import Select from "../../../components/atoms/Select";

import { selectCategoryData } from "../../../components/selectDatas/columnNews";
import { TaggingColumn } from "../../../components/columns/TaggingColumn";

function List() {
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tagData, setTagData] = useState([]);
    const [tagName, setTagName] = useState("");
    const [tagColor, setTagColor] = useState("");
    const [tagCategory, setTagCategory] = useState(0);
    const [categoryRadioValue, setCategoryRadioValue] = useState(0);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    useEffect(async () => {
        tagLoad();
    }, [categoryRadioValue]);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        },
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        tagAddSubmit();
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setTagName("");
    };

    const tagLoad = async () => {
        try {
            setLoading(true);

            const query = {
                b_category: categoryRadioValue,
            };

            const response = await TaggingApi.List(query);
            const processedData = response.data.map((item) => {
                return {
                    key: item.TId,
                    ...item,
                };
            });

            setTagData(processedData);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const tagDelete = async () => {
        try {
            setLoading(true);

            if (selectedRowKeys.length < 1) {
                return message.warning("삭제할 태그를 선택해주세요.");
            }

            await TaggingApi.Delete(selectedRowKeys);
            tagLoad();
            message.success("삭제 성공");
        } catch (err) {
            console.log(err);
            message.error("삭제 실패");
        } finally {
            setLoading(false);
        }
    };

    const tagAddSubmit = async () => {
        try {
            if (tagName === "") {
                return message.warning("이름을 입력해주세요.");
            }

            if (tagColor === "") {
                return message.warning("색상을 입력해주세요.");
            }

            const data = {
                name: tagName,
                color: tagColor,
                b_category: tagCategory,
            };
            await TaggingApi.Create(data);

            message.success("태그가 추가되었습니다.");
            setTagName("");
            tagLoad();
            setIsModalVisible(false);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <Breadcrumb
                style={{
                    textAlign: "left",
                    margin: "16px",
                    fontWeight: "bold",
                    fontSize: 18,
                }}
            >
                <Breadcrumb.Item>태그 관리</Breadcrumb.Item>
                <Breadcrumb.Item>목록</Breadcrumb.Item>
            </Breadcrumb>
            <div
                className="site-layout-background"
                style={{ padding: 24, minHeight: "100vh" }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: 20,
                    }}
                >
                    <div
                        style={{
                            fontWeight: "bold",
                            fontSize: 16,
                        }}
                    >
                        태그 갯수 : {tagData.length}
                    </div>
                    <Button type="primary" onClick={showModal}>
                        태그 추가
                    </Button>
                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 20,
                    }}
                >
                    <Radio.Group
                        buttonStyle="solid"
                        value={categoryRadioValue}
                        onChange={async (e) => {
                            setCategoryRadioValue(e.target.value);
                        }}
                    >
                        {selectCategoryData.map((d) => (
                            <Radio.Button style={{ width: 80 }} value={d.value}>
                                {d.text}
                            </Radio.Button>
                        ))}
                    </Radio.Group>

                    <div>
                        <span style={{ marginRight: 12 }}>
                            선택된 태그 갯수 :
                            {selectedRowKeys.length > 0
                                ? ` ${selectedRowKeys.length}`
                                : " 0"}
                        </span>

                        <Popconfirm
                            title="삭제하시겠습니까?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => {
                                tagDelete();
                            }}
                        >
                            <Button type="danger">삭제하기</Button>
                        </Popconfirm>
                    </div>
                </div>

                <Table
                    loading={loading}
                    rowSelection={rowSelection}
                    columns={TaggingColumn}
                    dataSource={tagData}
                />
            </div>

            <Modal
                width={700}
                title="태그 추가"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="추가"
                cancelText="취소"
            >
                <div className={"input-frame"}>
                    <div className={"title"}>이름 :</div>
                    <Input
                        style={{
                            marginRight: 20,
                        }}
                        placeholder="이름을 입력해주세요."
                        value={tagName}
                        onChange={(e) => {
                            setTagName(e.target.value);
                        }}
                    />
                    <div className={"title"}>색상 :</div>
                    <Input
                        placeholder="ex) #ffffff"
                        value={tagColor}
                        onChange={(e) => {
                            setTagColor(e.target.value);
                        }}
                    />
                    <Select
                        style={{
                            width: "100%",
                            textAlign: "left",
                            marginLeft: 20,
                        }}
                        data={selectCategoryData}
                        value={tagCategory}
                        onChange={(value) => {
                            setTagCategory(value);
                        }}
                    />
                </div>
            </Modal>
        </>
    );
}

export default List;
