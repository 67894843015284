import { Select as AntdSelect } from "antd";

import "./index.css";

const { Option } = AntdSelect;

const Select = (props) => {
    const options = props.data.map((d) => (
        <Option value={d.value}>{d.text}</Option>
    ));

    return (
        <AntdSelect
            className="custom-select"
            style={{ width: 200, textAlign: "left" }}
            {...props}
        >
            {options}
        </AntdSelect>
    );
};

export default Select;
