import api from "./api";

export default class ContactApi {
    static async List(data) {
        return await api.get("contactUs/list", data);
    }

    static async StatusUpdate(data, params) {
        return await api.put("contactUs/list", data, params);
    }
}
