import axios from "axios";

const url = process.env.REACT_APP_HOST || "http://localhost:3001";
const baseURL = `${url}/api/`;

export const _axios = axios.create({
    baseURL,
});

export default class api {
    static async send({ url, method, params, data, headers }) {
        try {
            const res = await _axios.request({
                url,
                method,
                params,
                data,
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    ...headers,
                },
            });

            return res.data;
        } catch (ex) {
            console.error(ex);

            throw ex;
        }
    }

    static async get(url, params) {
        return await api.send({ method: "GET", url, params });
    }

    static async del(url, params, data) {
        return await api.send({ method: "DELETE", url, params, data });
    }

    static async post(url, data, params) {
        return await api.send({ method: "POST", url, data, params });
    }

    static async put(url, data, params) {
        return await api.send({ method: "PUT", url, data, params });
    }

    static async fileForm(url, file, method) {
        let formData = new FormData();
        formData.append("file", file);

        return await api.send({
            url,
            method: method || "PUT",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        });
    }
}
