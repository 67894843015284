export const selectStatusData = [
    {
        value: 0,
        text: "출시",
    },
    {
        value: 1,
        text: "보류",
    },
    {
        value: 2,
        text: "메인표출",
    },
    {
        value: 99,
        text: "삭제",
    },
];

export const selectCategoryData = [
    {
        value: 0,
        text: "컬럼",
    },
    {
        value: 1,
        text: "뉴스",
    },
    {
        value: 2,
        text: "활용사례",
    },
];

export const selectTypeFilterData = [
    {
        value: 99,
        text: "전체",
    },
    {
        value: 0,
        text: "Image",
    },
    {
        value: 1,
        text: "Movie",
    },
    {
        value: 2,
        text: "Text",
    },
];

export const selectTypeData = [
    {
        value: 0,
        text: "Image",
    },
    {
        value: 1,
        text: "Movie",
    },
    {
        value: 2,
        text: "Text",
    },
];
