import api from "./api";

export default class SubscribeApi {
    static async List(data) {
        return await api.get("subscribe/list", data);
    }

    static async StatusUpdate(data, params) {
        return await api.put("subscribe/list", data, params);
    }
}
