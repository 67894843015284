import { Route, Switch, Redirect } from "react-router-dom";

import ColumnNewsList from "../pages/Contents/ColumnNews/List";
import ColumnNewsEdit from "../pages/Contents/ColumnNews/Edit";

import JobPostingList from "../pages/Contents/JobPosting/List";
import JobPostingEdit from "../pages/Contents/JobPosting/Edit";

import ContactUsList from "../pages/Contents/ContactUs/List";
import SubscribeList from "../pages/Contents/Subscribe/List";
import TaggingList from "../pages/Contents/Tagging/List";

const ContentRouter = () => {
    return (
        <Switch>
            <Route exact={true} path="/Home/Board" component={ColumnNewsList} />
            <Route
                path="/Home/Board/edit/:boardId"
                component={ColumnNewsEdit}
            />
            <Route path="/Home/Board/edit" component={ColumnNewsEdit} />

            <Route
                exact={true}
                path="/Home/Jobposting"
                component={JobPostingList}
            />
            <Route
                path="/Home/Jobposting/edit/:jobpostingId"
                component={JobPostingEdit}
            />
            <Route path="/Home/Jobposting/edit" component={JobPostingEdit} />

            <Route path="/Home/ContactUs" component={ContactUsList} />
            <Route path="/Home/Subscribe" component={SubscribeList} />
            <Route path="/Home/Tagging" component={TaggingList} />

            <Route component={() => <Redirect to="/Home/Board" />} />
        </Switch>
    );
};

export default ContentRouter;
