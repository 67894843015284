import { useEffect, useState } from "react";
import { Breadcrumb, Button, Input, message } from "antd";
import { useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";

import "../../../css/ColumnNews/Edit.css";

import UploadApi from "../../../api/upload";
import JobpostingApi from "../../../api/jobposting";

import Select from "../../../components/atoms/Select";
import { selectStatusData } from "../../../components/selectDatas/columnNews";

function Edit(props) {
    let { jobpostingId } = useParams();

    const [loading, setLoading] = useState(false);
    const [editInitialValue, setEditInitialValue] = useState("");
    const [jobpostingData, setJobpostingData] = useState({
        Occupation: "",
        Occupation_Detail: "",
        Worker_Type: "",
        Contents: "",
        Status: 0,
    });

    useEffect(() => {
        if (jobpostingId) {
            jobpostingLoad();
        }
    }, []);

    const jobpostingLoad = async () => {
        try {
            const query = {
                jId: jobpostingId,
            };
            const { data } = await JobpostingApi.Detail(query);

            setJobpostingData(data);
            setEditInitialValue(data.Contents);
        } catch (err) {
            console.log(err);
        }
    };

    const handleEditorChange = (e) => {
        setJobpostingData({
            ...jobpostingData,
            Contents: e.target.getContent(),
        });
    };

    const imageUploadHandler = async (blobInfo, success, failure) => {
        {
            try {
                const response = await UploadApi.upload(blobInfo.blob());

                if (response.data?.location) {
                    success(response.data.location);
                } else {
                    failure("이미지 업로드 실패!");
                }
            } catch (err) {
                console.log(err);
            }
        }
    };

    const saveSubmit = async () => {
        try {
            setLoading(true);

            if (jobpostingData.Occupation === "") {
                return message.warning("직업을 입력해주세요.");
            }

            if (jobpostingData.Occupation_Detail === "") {
                return message.warning("직업 상세를 입력해주세요.");
            }

            if (jobpostingId) {
                await JobpostingApi.Update(jobpostingData);
                props.history.goBack();
                message.success("정상적으로 수정되었습니다.");
            } else {
                await JobpostingApi.Create(jobpostingData);
                props.history.goBack();
                message.success("정상적으로 등록되었습니다.");
            }
        } catch (err) {
            console.log(err);
            message.error("통신에 실패하였습니다.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Breadcrumb
                    style={{
                        textAlign: "left",
                        margin: "16px",
                        fontWeight: "bold",
                        fontSize: 18,
                    }}
                >
                    <Breadcrumb.Item>채용 공고</Breadcrumb.Item>
                    <Breadcrumb.Item>목록</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {jobpostingId ? "수정" : "등록"}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Button
                    type="primary"
                    size="middle"
                    style={{
                        marginRight: 10,
                    }}
                    onClick={saveSubmit}
                    loading={loading}
                >
                    {jobpostingId ? "수정" : "등록"}
                </Button>
            </div>
            <div
                className="site-layout-background"
                style={{ padding: 24, minHeight: "100vh" }}
            >
                <div className={"input-frame"}>
                    <div className={"title"}>직업 :</div>
                    <Input
                        placeholder="직업을 입력해주세요. ex)개발, 디자인"
                        value={jobpostingData.Occupation}
                        onChange={(e) => {
                            setJobpostingData({
                                ...jobpostingData,
                                Occupation: e.target.value,
                            });
                        }}
                    />
                </div>

                <div className={"input-frame"}>
                    <div className={"title"}>직업 상세 :</div>
                    <Input
                        placeholder="직업 상세를 입력해주세요. ex)웹퍼블리셔(프론트엔드),  UI/UX 디자이너"
                        value={jobpostingData.Occupation_Detail}
                        onChange={(e) => {
                            setJobpostingData({
                                ...jobpostingData,
                                Occupation_Detail: e.target.value,
                            });
                        }}
                    />
                </div>

                <div className={"input-frame"}>
                    <div className={"title"}>직종 :</div>
                    <Input
                        placeholder="직종을 입력해주세요. ex)정규직, 계약직"
                        value={jobpostingData.Worker_Type}
                        onChange={(e) => {
                            setJobpostingData({
                                ...jobpostingData,
                                Worker_Type: e.target.value,
                            });
                        }}
                    />
                </div>

                <div className={"input-frame"}>
                    <div className={"title"}>상태 :</div>
                    <Select
                        className={"content"}
                        style={{
                            width: "100%",
                            textAlign: "left",
                        }}
                        data={selectStatusData}
                        value={jobpostingData.Status}
                        onChange={(value) => {
                            setJobpostingData({
                                ...jobpostingData,
                                Status: value,
                            });
                        }}
                    />
                </div>
                <Editor
                    apiKey="fpkgh57ghp4vy8jj8o7s3fykta3aofbjjmkh7fiti3x3q5k0"
                    initialValue={editInitialValue}
                    init={{
                        language: "ko_KR", //한글판으로 변경
                        selector: "textarea#full-featured-non-premium",
                        plugins:
                            "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                        imagetools_cors_hosts: ["picsum.photos"],
                        menubar:
                            "file edit view insert format tools table help",
                        toolbar:
                            "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
                        toolbar_sticky: true,
                        autosave_ask_before_unload: true,
                        autosave_interval: "30s",
                        autosave_prefix: "{path}{query}-{id}-",
                        autosave_restore_when_empty: false,
                        autosave_retention: "2m",
                        image_advtab: true,
                        link_list: [
                            {
                                title: "My page 1",
                                value: "https://www.tiny.cloud",
                            },
                            {
                                title: "My page 2",
                                value: "http://www.moxiecode.com",
                            },
                        ],
                        image_list: [
                            {
                                title: "My page 1",
                                value: "https://www.tiny.cloud",
                            },
                            {
                                title: "My page 2",
                                value: "http://www.moxiecode.com",
                            },
                        ],
                        image_class_list: [
                            { title: "None", value: "" },
                            { title: "Some class", value: "class-name" },
                        ],
                        importcss_append: true,
                        file_picker_callback: function (callback, value, meta) {
                            /* Provide file and text for the link dialog */
                            if (meta.filetype === "file") {
                                callback(
                                    "https://www.google.com/logos/google.jpg",
                                    { text: "My text" }
                                );
                            }

                            /* Provide image and alt text for the image dialog */
                            if (meta.filetype === "image") {
                                callback(
                                    "https://www.google.com/logos/google.jpg",
                                    { alt: "My alt text" }
                                );
                            }

                            /* Provide alternative source and posted for the media dialog */
                            if (meta.filetype === "media") {
                                callback("movie.mp4", {
                                    source2: "alt.ogg",
                                    poster: "https://www.google.com/logos/google.jpg",
                                });
                            }
                        },
                        images_upload_handler: async function (
                            blobInfo,
                            success,
                            failure
                        ) {
                            imageUploadHandler(blobInfo, success, failure);
                        },
                        templates: [
                            {
                                title: "New Table",
                                description: "creates a new table",
                                content:
                                    '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
                            },
                            {
                                title: "Starting my story",
                                description: "A cure for writers block",
                                content: "Once upon a time...",
                            },
                            {
                                title: "New list with dates",
                                description: "New List with dates",
                                content:
                                    '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
                            },
                        ],
                        template_cdate_format:
                            "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
                        template_mdate_format:
                            "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
                        height: 800,
                        image_caption: true,
                        quickbars_selection_toolbar:
                            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                        noneditable_noneditable_class: "mceNonEditable",
                        toolbar_mode: "sliding",
                        contextmenu: "link image imagetools table",
                        skin: "oxide-dark",
                        content_css: "dark",
                        content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                    onChange={handleEditorChange}
                />
            </div>
        </>
    );
}

export default Edit;
