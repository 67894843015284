import { formatByString } from "../../utils/Date";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

export const ContactColumn = [
    {
        title: "No.",
        width: 50,
        dataIndex: "CId",
        key: "CId",
    },
    {
        title: "회사",
        width: 100,
        dataIndex: "Company",
        key: "Company",
    },
    {
        title: "이메일",
        width: 100,
        dataIndex: "Email",
        key: "Email",
    },
    {
        title: "담당자",
        width: 80,
        dataIndex: "Manager",
        key: "Manager",
    },
    {
        title: "참고사이트",
        width: 150,
        dataIndex: "Reference_Site",
        key: "Reference_Site",
    },
    {
        title: "AudienceM",
        width: 80,
        dataIndex: "S_AudienceM",
        key: "S_AudienceM",
        align: "center",
        render: (value, record, index) => {
            return (
                <div>
                    {value ? (
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                    ) : (
                        <CloseCircleTwoTone twoToneColor="#eb2f96" />
                    )}
                </div>
            );
        },
    },
    {
        title: "콘텐츠 제휴",
        width: 80,
        dataIndex: "S_Content",
        key: "S_Content",
        align: "center",
        render: (value, record, index) => {
            return (
                <div>
                    {value ? (
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                    ) : (
                        <CloseCircleTwoTone twoToneColor="#eb2f96" />
                    )}
                </div>
            );
        },
    },
    {
        title: "Development",
        width: 80,
        dataIndex: "S_Development",
        key: "S_Development",
        align: "center",
        render: (value, record, index) => {
            return (
                <div>
                    {value ? (
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                    ) : (
                        <CloseCircleTwoTone twoToneColor="#eb2f96" />
                    )}
                </div>
            );
        },
    },
    {
        title: "문의 요청 날짜",
        width: 100,
        dataIndex: "Request_Date",
        key: "Request_Date",
        render: (value, record, index) => {
            return <div>{formatByString(value, "yyyy-MM-dd HH:mm")}</div>;
        },
    },
];
